module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ExpertSender","short_name":"ExpertSender","start_url":"/","background_color":"#ffffff","theme_color":"#142e7b","display":"standalone","icon":"src/images/icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"36cb7e0e72beb2d7ee64a124e46f5292"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-page-progress/gatsby-browser.js'),
      options: {"plugins":[],"height":2,"color":"#142e7b","includePaths":[{"regex":"^/blog"}],"excludePaths":["/blog/","/blog"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/buddy/expertsender-com-br/src/intl","languages":["br"],"defaultLanguage":"br","redirect":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["UniNeue"],"urls":["/fonts.css"]},"google":{"families":["Roboto"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WR5VF2W"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
